@import './imports.scss';

.slider-of-products-photo__photo{
    width: 100%;
    height: 100%;

    content: '';
    background-repeat: no-repeat;
    background-size: cover;
}

.swiper.slider-of-products-photo {
    // display: flex;
    // flex-direction: column;
    // gap: 2rem;

    // align-items: center;

    position: absolute !important;

    width: 100%;
    height: 100%;

    top: 0;
    left: 0;

    @include breakpoint($mobile-bp){
        gap: 1rem;
    }
}