$mobile-bp: 700px;
$wide-xl-bp: 2400px;
$wide-bp: 1600px;
$wide-xs: 1400px;

$red: rgb(201, 28, 28);
$grey--light: rgb(98, 95, 94);
$grey--dark: rgb(59, 59, 59);

$grey--white: rgb(245, 245, 245);

