@import './imports.scss';

header{
    button{
        font-size: 1rem;
    }
}

.form-payment {
    display: flex;
    flex-direction: column;

    gap: 2rem;

    background-color: white;

    padding: 1.5rem;

    background-color: $grey--dark;

    padding-top: 2rem;


    h1, h2{
        color: white;


    }

    
}

.sum-control {
    display: flex;
    gap: .5rem;
    justify-content: space-between;

    button {
        padding:1rem 1rem;
        background-color: $grey--light;
        border: none;
        cursor: pointer;
        transition: all .3s ease-in-out;
        width: 100%;

        color: white ;
        font-size: .8rem;

        &:hover{
            filter: brightness(1.1);
        }

        &.active{
            filter: brightness(1.5);
        }
    }
}

.format-control{
    display: flex;
    width: 100%;
    
    
    button{
        border: none;
        padding: 1.5rem;
        background-color: $grey--dark;
        width: 100%;
        cursor: pointer;
        color: white;
        font-size: 1rem;

        &.active{
            background-color: $grey--light;
        }
    }
}

.pay-button{
    padding: 1.5rem;
    cursor: pointer;
    border: none;

    font-size: 1rem;
    transition: all ease-in-out .3s;

    &:hover{
        background-color: rgba($color: $red, $alpha: 1.0);
        color: white;
    }
}

.form-payment__input{
    padding: 1rem;
    border:none;

    width: 100%;
    box-sizing: border-box;
}


.form-h2{
    margin-bottom: 1rem;
    font-size: 1rem;
}