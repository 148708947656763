@import './imports.scss';

footer {
    

    padding: 2rem 0;
    padding-top: 4rem;
    * {
        color: white !important;
    }

    a {
        font-weight: 100;
    }

    display: flex;
    justify-content: space-between;

    @include breakpoint($mobile-bp){
        flex-direction: column;

        padding: 2rem 2rem;
    }
}

.block-footer{
    background-color: $grey--dark;
}