@import './imports.scss';

.you-photos.swiper {
    display: flex;
    flex-direction: column;

    gap: 2rem;

    overflow: hidden;


    .swiper-pagination{
        position: relative;
    }

    .swiper-image{
        width: 100%;
        height: 18rem;
        background-size: cover;
        background-position: center;
    }

    .swiper-pagination-bullet{

    }

    .swiper-pagination{
        height: 2rem;
        display: flex;
        justify-content: center;
        align-items: center;
        gap: .5rem;

        .pagination-red{
            display: block;
            width: .5rem;
            height: .5rem;
            content: '';
            background-color: rgb(59, 59, 59);
            border-radius: 50%;
        }
    }

    .pagination-red.swiper-pagination-bullet-active{
        background-color: $red;

        // width: 1rem;
        // height: 1rem;
    }

    .swiper-pagination-bullet-active{
        background-color: $grey--light;
    }
}


.your-donation-container{
    background-color: $grey--white;
    padding: 2rem;

    @include breakpoint($mobile-bp){
        padding: 1rem;
    }
}

.h4-flex{
    // display: flex;
    // gap: .3rem;
}

.animated-counter{
    color:$red;
}