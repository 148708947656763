@import './components/imports.scss';

.block-wrapper {
    max-width: 80rem;
    margin: 0 auto;

    padding: 0rem 3rem;

    position: relative;

    width:100%;

    overflow: hidden;

    @include breakpoint($mobile-bp) {
        padding: 0rem;
    }

    @include breakpoint($wide-xl-bp) {
        max-width: 90rem;
    }

    @include breakpoint($wide-bp) {
        max-width: 65rem;
    }
}

.block-background {
    // min-height: 100vh;

    padding: 3rem 0;
    box-sizing: border-box;

    background-size: cover !important;
    background-repeat: no-repeat !important;

    display: flex;
    align-items: flex-start;

    @include breakpoint($mobile-bp) {
        padding: 1.5rem 0;
    }

    &:last-child{
        // padding-bottom: 0;
        min-height: unset;
    }
}

.block:nth-child(1) {
    // padding-top: 2rem;

    @include breakpoint($wide-xs) {
        // padding-top: 4rem;
    }

    .block-background {
        padding-top: 0;
    }
}

.add-left-line {
    padding-left: 1rem;
    position: relative;

    &::after {
        width: 0.3rem;
        position: absolute;
        height: 100%;

        content: '';

        background-color: $red;

        top: 0;
        left: 0;
    }

    @include breakpoint($mobile-bp){
        padding-top: 1rem;
        padding-left: unset;
        
        &::after{
            width: 25%;
            height: 0.3rem;
            
            
        }
    }
}

ul {
    padding-left: 0;
    display: flex;
    flex-direction: column;
    gap: .5rem;

    li {
        list-style: none;
        position: relative;
        padding-left: 1rem;


        &::before {
            content: '';
            width: 0.3rem;
            height: 0.3rem;

            left: 0;
            top: 35%;

            background-color: $red;
            position: absolute;
        }
    }
}
