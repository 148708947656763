@import './imports.scss';

header{
    display: grid;
    grid-template-columns: 1fr 2fr;
    gap: 2rem;


    form{
        color: white;
    }

    @include breakpoint ($wide-xs){
        grid-template-columns: 1fr 1fr;
    }

    @include breakpoint($wide-bp) {
        display: flex;
        width: 100%;

        .header-left {
            // width: 47%;
        }

        .header-image{
            width: 100%;
        }
    }

    @include breakpoint($mobile-bp){
        display: flex;
        flex-direction: column-reverse;
        gap: 0;

        .header-image{
            height:10rem;
        }

    
    }
}

.header-image{
    content: '';
    background-repeat: no-repeat;
    background-size: cover;
    
}

.header-left__name-of-project{
    background-color: $red;
    padding: 1rem;
    display: inline;
    text-transform: uppercase;
    color: white;

    font-size: 1rem;

    box-sizing: border-box;
    display: inline-block;


}

.header-left h1{
    margin-top: 2rem;
    margin-bottom: 2rem;

    @include breakpoint($mobile-bp){
        margin: 1rem 0;
        font-size: 1.5rem;
    }
}

.header-left{
    // box-sizing: border-box;
}