@import './imports.scss';

.accordion-transition-entering {
    max-height: 0;
    overflow: hidden;
    transition: max-height 300ms ease-in-out;
}

.accordion-transition-entered {
    max-height: 500px; /* Adjust as needed */
    transition: max-height 300ms ease-in-out;
}

.accordion-transition-exiting {
    max-height: 500px; /* Adjust as needed */
    transition: max-height 300ms ease-in-out;
}

.accordion-transition-exited {
    max-height: 0;
    overflow: hidden;
}

.faq-container {
    display: flex;
    flex-direction: column;
    gap: 2rem;
}

.accordion {
    display: flex;
    flex-direction: column;
    // gap: 1rem;

    &:hover {
    }

    button {
        padding: 1rem;
        font-size: 1rem;
        font-weight: 600;
        background-color: $grey--white;

        color: $grey--dark;
        border: 1px solid rgba($color: $grey--dark, $alpha: 0.1);
        text-align: left;

        width: 100%;
        cursor: pointer;

        transition: all 0.3s ease-in-out;

        &:hover {
            background-color: $red;
            color: white;
        }
    }

    @include breakpoint($mobile-bp) {
        h3 {
            padding-left: 1rem;
        }
    }
}

.accordion-collapse {
    padding: 1rem;
    border: 1px solid rgba($color: $grey--dark, $alpha: 0.1);
}

.accordion h3 {
    margin-bottom: 1rem;
}
