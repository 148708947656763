@import './imports.scss';

.instructions {
    display: grid;
    grid-template-columns: 2fr 1fr;

    // display: flex;

    background-color: $grey--white;

    // padding: 2rem;

    * {
        min-width: 0;
    }

    @include breakpoint(1000px) {
        display: block;
        height: 50rem;
    }

    @include breakpoint($mobile-bp){
        height: unset;
    }
}

.swiper-instructions--inner {
    display: none;
    @include breakpoint(1000px) {
        display: block;
    }

    img {
        object-fit: contain;
    }

    @include breakpoint($mobile-bp){
        display: none;
    }
}

.instructions-left {
    display: grid;
    grid-template-areas:
        'btn btn btn'
        'stps1 stps1 img'
        'stps2 stps2 img';

    > *:not(:first-child) {
        // background-color: red;
        padding: 2rem;
    }

    grid-template-columns: 1fr 1fr 2fr;
    grid-template-rows: 0.3fr 1fr 1fr;

    @include breakpoint($mobile-bp){
        display: flex; 
        flex-direction: column;   
    }
}

.instructions__controls {
    display: flex;
    justify-content: space-between;

    grid-area: btn;

    
}

.instructions-controls__button {
    padding: 2rem 2rem;
    background-color: $grey--dark;

    width: 100%;

    color: white;
    text-align: center;

    cursor: pointer;
    transition: all 0.3s ease-in-out;

    display: flex;
    justify-content: center;
    align-items: center;

    position: relative;

    &::before {
        content: '';
        height: 100%;
        width: 1px;
        color: white;
        position: absolute;
        top: 0;
        right: 0;
    }

    &:hover {
        filter: brightness(1.3);
    }

    &--active {
        background-color: $red;

        &:hover {
            filter: brightness(1);
        }
    }

    @include breakpoint($mobile-bp){
        padding: 1rem 0rem;
    }
}

.swiper-pagination-bullet.swiper-pagination-bullet-active {
    background-color: $grey--white;
}
.swiper-pagination-bullet.pagination-red.swiper-pagination-bullet-active {
    background-color: $red;
}

.swiper-instructions {
    // padding-bottom: 2rem ;
    // padding-right: 2rem ;

    .swiper-slide {
        display: flex;
        justify-content: center;
        width: 100%;
    }

    .swiper-pagination {
        position: relative;
    }

    &--right {
        background-color: $grey--dark;
        filter: brightness(1.2);

        > * {
            filter: brightness(0.8);
        }

        @include breakpoint(1000px) {
            background-color: red;
            display: none;
        }

        padding: 1rem 0 !important;
    }
}

.instructions__steps {
    // padding-bottom: 2rem;
    // padding-right: 2rem;

    padding: 2rem;
    grid-area: stps;
}
