@import './imports.scss';

.image-with-descrpiption {
    display: flex;
}

.beneficiaries-list {
    display: grid;
    grid-template-columns: repeat(4, 1fr);

    .item {
        display: flex;
        flex-direction: column;
    }
}

.about-company {
    display: grid;
    grid-template-columns: 1fr 1fr;

    // gap: 2rem;

    background-color: $grey--white;

    // padding: 2rem;

    margin-bottom: 2rem;

    @include breakpoint($mobile-bp){
        display: flex;
        flex-direction: column;
        gap: 1rem;
    }
}

.image-with-descrpiption__image{
    content: '';
    width: 100%;
    height: 100%;
    background-size: cover;
}

.about-company__left{
    padding: 2rem;

    @include breakpoint($mobile-bp){
        padding: 1rem;
    }
}

h2{
    font-size: 2rem;
}