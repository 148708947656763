@import './components/imports.scss';

body {
    margin: 0;
    font-family: 'Roboto', sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

html {
    font-size: 16px; /* Размер шрифта корневого элемента */
}

*{
    color: $grey--dark;
}

a {
    text-decoration: none;
    color: inherit;
}

h1,h2,h3,h4,h5,h6{
    margin: unset;
}