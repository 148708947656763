@import './imports.scss';

nav {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 1rem 0rem;

    color: $grey--dark;

    position: relative;

    gap: 2rem;

    @include breakpoint($mobile-bp) {
        flex-direction: column;
        gap: 3rem;
        font-size: 1.2rem;
        // color: white;
    }
}

.open-menu-button {
    position: fixed;
    top: 1rem;
    right: 1rem;
    z-index: 10;

    width: 3rem;
    height: 3rem;

    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;

    background-color: $red;
    
    *{
        color: white;
    }
}

.block-nav {
    position: fixed;
    width: 100%;

    top: 0;
    backdrop-filter: blur(2rem);

    background-color: rgba($color: $grey--white, $alpha: 0.4);
    // backdrop-filter: blur(1rem);

    .block-wrapper {
        padding: 0rem 3rem;

        width: 100%;
        max-width: 1850px;

        box-sizing: border-box;
    }

    z-index: 5;
}

nav img {
    max-height: 4rem;
}

.activeLink {
    font-weight: 900;
    // text-decoration: underline;
    color: $red;
}

.menu__company-logo {
    height: 100%;
    width: 15rem;
}

a {
    transition: all 0.2s ease-in-out;
    cursor: pointer;

    &:hover {
        color: $red;
    }
}

.main-links {
    display: flex;
    // gap: 2rem;
    color: $grey--dark;

    text-transform: uppercase;
    align-items: stretch;

    @include breakpoint($wide-xs) {
        font-size: 0.8rem;

        //     // gap: 1rem;
        text-align: center;
        //     display: flex;
        //     justify-content: center;
        //     align-items: center;
    }
    * {
        position: relative;

        padding: 0 1rem;
        // height: 100%;
    }

    & > *:not(:last-child) {
        &::before {
            content: '';
            position: absolute;
            top: 0;
            right: 0;
            height: 100%;
            width: 1px;
            background-color: $red;
        }
    }

    a {
        vertical-align: middle;
    }

    @include breakpoint($mobile-bp){
        display: flex;
        flex-direction: column;

        color: white;

        font-size: 1rem;
        gap: 1rem;

        *::before{
            display: none;
        }
    }
}

.languages-list {
    display: flex;

    gap: 1rem;

    .language-list__item {
        border-radius: 50%;
        border: 2px solid $grey--dark;
        width: 2rem;
        height: 2rem;
        padding: 0;

        color: $grey--dark;

        box-sizing: border-box;
        background: none;

        font-weight: 700;
        cursor: pointer;

        transition: all 0.3s ease-in-out;

        &:hover {
            background-color: $red;
            border: 0;
            color: white;
        }

        &--active {
            background-color: $red;
            border: 0;
            color: white;
        }

        @include breakpoint($mobile-bp){
            color: white;
            border: white solid 1px;
           
            &--active{
                border: $red solid 1px;
            }
        }
    }
}

.menu-fake {
    height: 6rem;
}
